<template>
  <section class="page-container">
        <div class="loader"></div>
        <header>
            <div class="container">
                <div class="row">
                    <div class="col-sm-8 header">
                        <router-link to="/" custom v-slot="{ navigate }">
                          <h1 @click="navigate()">
                            <div class="inline-block">
                                Martin Haltrich
                                <small class="block">Psychotherapeutische Praxis</small>
                            </div>
                          </h1>
                        </router-link>
                        <a class="menu-button" href="#" @click.prevent="showMenu()" v-html="octicons['three-bars'].toSVG({ width: 20 })"></a>
                    </div>
                    <div class="col-sm-4 text-right text-left-xs hidden-xs">
                        <a href="tel:+4367763539373">
                            <span class="type--fade">+43 677 63 53 93 73</span>
                        </a>
                        <router-link to="/kontakt">
                            <span class="underline active">Kontakt</span>
                        </router-link>
                    </div>
                    <div class="col-sm-12 hidden-xs">
                        <Menu></Menu>
                    </div>
                </div>
                <!--end of row-->
            </div>
            <!--end of container-->
        </header>
        <div class="main-container transition--fade">
            <router-view></router-view>
            <footer>
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12">
                            &copy; {{ (new Date()).getFullYear() }} Martin Haltrich | <router-link to="/datenschutz">Datenschutzerklärung</router-link>
                        </div>
                    </div>
                    <!--end of row-->
                </div>
                <!--end of container-->
            </footer>
        </div>
        <div class="mobile-menu">
            <Menu></Menu>
            <a class="close-button" href="#" @click.prevent="closeMenu()" v-html="octicons.x.toSVG({ width: 20 })"></a>
        </div>
    </section>
</template>

<script>
import $ from 'jquery'
import octicons from '@primer/octicons'
import Menu from './components/Menu.vue'

export default {
  components: {Menu},
  data () {
    return { octicons }
  },
  methods: {
    showMenu () {
      $('.mobile-menu').fadeIn()
    },
    closeMenu () {
      $('.mobile-menu').fadeOut()
    }
  }
}
</script>

<style lang="scss">
    @import 'main.scss';
</style>